// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  signUpUrl: "https://teximarcobackend.herokuapp.com/api/accounts/signup",
  loginUrl: "https://teximarcobackend.herokuapp.com/api/accounts/login",
  profileUrl: "https://teximarcobackend.herokuapp.com/api/accounts/profile",
  invoiceUrl: "https://teximarcobackend.herokuapp.com/api/operations/invoice",
  emailUrl: "https://teximarcobackend.herokuapp.com/api/message/send",

  firebaseConfig : {
    apiKey: "AIzaSyDN2-tHY3R_A7O6XIQe36mVU3y83XO-z_U",
  authDomain: "teximarcointl.firebaseapp.com",
  projectId: "teximarcointl",
  storageBucket: "teximarcointl.appspot.com",
  messagingSenderId: "698621657271",
  appId: "1:698621657271:web:5d4d7b6e4202ddacf44d32"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
