import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import {  FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { invoice } from "./invoice";
import { AngularFireDatabaseModule, AngularFireDatabase, snapshotChanges } from "@angular/fire/database";
//import * as firebase from 'firebase';
import {AngularFireStorage, AngularFireUploadTask,AngularFireStorageReference,} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ImagesService } from '../images.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.css']
})
export class CreateInvoiceComponent implements OnInit {                                                                                                                                                                                                                          
  config = environment;                                              
  btnDisabled = false;
  selectedItem
  invalid: boolean;
  selectedGlObject;
  selectedGlObjectCode;
  selectedGlObjectType;
  invoice = invoice;
  invoices: any;
  unchangedInvoice: any;
  onDe2; onDe3; onDe4; onDe5; onDe6; onDe7; onDe8; onDe9; onDe10; onDe11; onDe12; onDe13; onDe14; onDe15;
  ref: AngularFireStorageReference;

  // tasks for the 10 tasks
  task1: AngularFireUploadTask;
  task2: AngularFireUploadTask;
  task3: AngularFireUploadTask;
  task4: AngularFireUploadTask;
  uploadState1: Observable<string>;
  uploadState2: Observable<string>;
  uploadState3: Observable<string>;
  uploadState4: Observable<string>;
  uploadProgress1: Observable<number>;
  uploadProgress2: Observable<number>;
  uploadProgress3: Observable<number>;
  uploadProgress4: Observable<number>;
  url1: '';
  url2: '';
  url3: '';
  url4: '';
   
   constructor(
  private router: Router,
  public data: DataService,
  private rest: RestApiService,
  private formBuilder: FormBuilder,
  private db: AngularFireDatabase,
  private afStorage: AngularFireStorage, 
   private image: ImagesService,
    ) { }
  
  async ngOnInit() {
      this.data.getProfile()
      this.Invoice()    
  }

    // For first upload
    async upload1(event, _url1) {
      const id1 = Math.random().toString(36).substring(2);
      this.ref = this.afStorage.ref(id1);
      this.task1 = this.ref.put(event.target.files[0]);
      this.uploadState1 = this.task1.snapshotChanges().pipe(map(s => s.state));
      this.uploadProgress1 = this.task1.percentageChanges();
      //this.downloadURL = (await this.task).downloadURL();
      //method to retrieve download url
   this.getUrl1(await this.task1)
    }
    private async getUrl1(snap: firebase.default.storage.UploadTaskSnapshot) {
      const url1 = await snap.ref.getDownloadURL();
      this.url1 = url1.toString();  //store the URL
      //console.logog(url1);
      //firebase.database().ref('/carnelian').set(url);
      
    }
  
  
  
  
  // For second upload
    async upload2(event, _url2) {
      const id2 = Math.random().toString(36).substring(2);
      this.ref = this.afStorage.ref(id2);
      this.task2 = this.ref.put(event.target.files[0]);
      this.uploadState2 = this.task2.snapshotChanges().pipe(map(s => s.state));
      this.uploadProgress2 = this.task2.percentageChanges();
      //this.downloadURL = (await this.task).downloadURL();
      //method to retrieve download url
   this.getUrl2(await this.task2)
    }
    private async getUrl2(snap: firebase.default.storage.UploadTaskSnapshot) {
      const url2 = await snap.ref.getDownloadURL();
      this.url2 = url2.toString();  //store the URL
      //console.logog(url2);
      //firebase.database().ref('/carnelian').set(url);
      
    }
  
  
  
    //For third upload
    async upload3(event, _url3) {
      const id3 = Math.random().toString(36).substring(2);
      this.ref = this.afStorage.ref(id3);
      this.task3 = this.ref.put(event.target.files[0]);
      this.uploadState3 = this.task3.snapshotChanges().pipe(map(s => s.state));
      this.uploadProgress3 = this.task3.percentageChanges();
      //this.downloadURL = (await this.task).downloadURL();
      //method to retrieve download url
   this.getUrl3(await this.task3)
    }
    private async getUrl3(snap: firebase.default.storage.UploadTaskSnapshot) {
      const url3 = await snap.ref.getDownloadURL();
      this.url3 = url3.toString();  //store the URL
      //console.logog(url3);
      //firebase.database().ref('/carnelian').set(url); 
    }
  
    // For fourth upload
    async upload4(event, _url4) {
      const id4 = Math.random().toString(36).substring(2);
      this.ref = this.afStorage.ref(id4);
      this.task4 = this.ref.put(event.target.files[0]);
      this.uploadState4 = this.task4.snapshotChanges().pipe(map(s => s.state));
      this.uploadProgress4 = this.task4.percentageChanges();
      //this.downloadURL = (await this.task).downloadURL();
      //method to retrieve download url
   this.getUrl4(await this.task4)
    }
    private async getUrl4(snap: firebase.default.storage.UploadTaskSnapshot) {
      const url4 = await snap.ref.getDownloadURL();
      this.url4 = url4.toString();  //store the URL
      //console.logog(url4);
      //firebase.database().ref('/carnelian').set(url); 
    }
  
  
    
  async Invoice(){
      try {
        const data = await this.rest.get(
          `${this.config.invoiceUrl}`        
        );
        data['success']
          ? (this.invoices = data['invoice'], this.unchangedInvoice = data['invoice'])
          : this.data.error(data['message']);
      } catch (error) {
        this.data.error(error['message']);
      }
      
    }
    
    formatDate(date){
      var dd = date.getDate();
      var mm = date.getMonth()+1;
      var yyyy = date.getFullYear();
      if(dd<10) {dd='0'+dd}
      if(mm<10) {mm='0'+mm}
      date = mm+'/'+dd+'/'+yyyy;
      return date
   }
  
   get todaysDate(){
    var result = [];
   var d = new Date();
   d.setDate(d.getDate())
   result.push(this.formatDate(d))
   return (result.join(','));
    }

    get invoiceNumber(){
      try{
      var i = 0;
      var total = []
      var totals = []
     this.invoices.forEach((data) => {
            total.push(data.number)
            i = total[0]
     })
      return i
    } catch (error) {
      this.data.error(error['message']);
    }
    }

    get invoicesubTotal(){
      //this.invoice.amount1
      var totals =0
      var total = ( +this.invoice.amount1 +  +this.invoice.amount2 + +this.invoice.amount3 + +this.invoice.amount4 + +this.invoice.amount5
        + +this.invoice.amount6 + +this.invoice.amount7 + +this.invoice.amount8 + +this.invoice.amount9 + +this.invoice.amount10
        + +this.invoice.amount11 + +this.invoice.amount12 + +this.invoice.amount13 + +this.invoice.amount14 + +this.invoice.amount15)
      return total
    }

    get vat(){
      var totals = 0;
            if (this.invoice.vater == true){
         totals = (5/100)*this.invoicesubTotal;
         var total = totals
      } else {
        var total = 0;
      }
     
      return total
    }

    get invoiceTotal(){
     var total = this.invoicesubTotal + this.vat
      return total
    }

    async onSubmit(){
      this.btnDisabled = true;
        this.invoice.preparedBy = this.data.user.firstname + " " + this.data.user.surname;
        this.invoice.subtotal = this.invoicesubTotal
        this.invoice.total = this.invoiceTotal
        this.invoice.vat = this.vat

       this.invoice.total = this.invoiceTotal
       if (this.invoice.company === 'JNC International Ltd'){
        this.invoice.address = '30, Raymond Njoku Street'
        this.invoice.address2 = 'Ikoyi, Lagos'
      }  

      if (this.invoice.bank === 'Access Bank'){
        this.invoice.accno = '0038914937'
        this.invoice.accname = 'Teximarco International Limited'
      } else if (this.invoice.bank === 'Zenith Bank'){
        this.invoice.accno = '1013975804'
        this.invoice.accname = 'Teximarco International Limited'
      }
      try { 
          const data = await this.rest.post(
            `${this.config.invoiceUrl}`,        
            this.invoice
          );
          data['success']
            ? this.router.navigate(['/operations/journals'])
              .then(() => this.data.success(data['message']))
              .catch(error => this.data.error(error))
            : this.data.error(data['message']);
            setTimeout(this.windowsReload.bind(this), 2000);
        
      } catch (error) {
        this.data.error(error['message']);
      }
      this.btnDisabled = false;
    }
  
    windowsReload(){
      window.location.reload();
    }
  

}
