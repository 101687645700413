
<div id="wrapper">
    <button type="button" class="btn" style="margin-left: 25px; margin-top: 45px; background-color: #537fb8;"> <a   style="color: white;" routerLink="/dashboard"><<< Back to Dashboard</a></button>
    <div class="main-content container">
<div class="box-content card white">
    <h4 class="box-title">Teximarco Payment Invoice</h4>
    <!-- /.box-title -->
    <div class="card-content sect">
        <form class="form-horizontal" #postForm="ngForm" novalidate>
            <div>
            <div class="form-group row">
                <label for="description" class="col-md-3"> First Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description1" name="description1" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description1" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> First Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount1" [(ngModel)]="invoice.amount1" >
                    </div>
            </div>
            <br>
            <div class="form-group row">
                <label for="description" class="col-md-3"> Second Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description2" name="description2" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description2" >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Second Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount2" [(ngModel)]="invoice.amount2" >
                    </div><br> <br>
            </div>
            <br>
            <div class="form-group row">
                <label for="description" class="col-md-3"> Third Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description3" name="description3" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description3" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Third Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount3" [(ngModel)]="invoice.amount3" >
                    </div><br> <br>
            </div>
            <br>
            <div class="form-group row">
                <label for="description" class="col-md-3"> Fourth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description4" name="description4" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description4" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Fourth Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount4" [(ngModel)]="invoice.amount4" >
                    </div><br> <br>
            </div>
            <br> 
            <div class="form-group row">
                <label for="description" class="col-md-3"> Fifth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description5" name="description5" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description5" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Fifth Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount5" [(ngModel)]="invoice.amount5" >
                    </div><br> <br>
            </div>
            <br>
            <div class="form-group row">
                <label for="description" class="col-md-3"> Sixth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description6" name="description6" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description6" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Sixth Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount6" [(ngModel)]="invoice.amount6" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe7"><button type="button" (click)="onDe7 = !onDe7" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
             <div class="form-group row" *ngIf="onDe7">
                <label for="description" class="col-md-3"> Seventh Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description7" name="description7" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description7" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Seventh Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount7" [(ngModel)]="invoice.amount7" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe8"><button type="button" (click)="onDe8 = !onDe8" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br> 
            <div class="form-group row" *ngIf="onDe8">
                <label for="description" class="col-md-3"> Eight Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description8" name="description8" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description8" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Eight Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount8" [(ngModel)]="invoice.amount8" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe9"><button type="button" (click)="onDe9 = !onDe9" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe9">
                <label for="description" class="col-md-3"> Ninth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description9" name="description9" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description9" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Ninth Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount9" [(ngModel)]="invoice.amount9" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe10"><button type="button" (click)="onDe10 = !onDe10" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe10">
                <label for="description" class="col-md-3"> Tenth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description10" name="description10" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description10" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Tenth Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount10" [(ngModel)]="invoice.amount10" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe11"><button type="button" (click)="onDe11 = !onDe11" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe11">
                <label for="description" class="col-md-3"> Eleventh Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description11" name="description11" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description11" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Eleventh Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount11" [(ngModel)]="invoice.amount11" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe12"><button type="button" (click)="onDe12 = !onDe12" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe12">
                <label for="description" class="col-md-3"> Twelfth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description12" name="description12" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description12" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Twelfth  Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount12" [(ngModel)]="invoice.amount12" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe13"><button type="button" (click)="onDe13 = !onDe13" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe13">
                <label for="description" class="col-md-3"> Thirteenth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description13" name="description13" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description13" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Thirteenth  Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount13" [(ngModel)]="invoice.amount13" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe14"><button type="button" (click)="onDe14 = !onDe14" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe14">
                <label for="description" class="col-md-3"> Fourteenth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description14" name="description14" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description14" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label"> Fourteenth  Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount14" [(ngModel)]="invoice.amount14" >
                    </div><br> <br>
                    <div class="input-group-btn" *ngIf="!onDe15"><button type="button" (click)="onDe15 = !onDe15" class="btn btn-success no-border text-white js__todo_button waves-effect waves-light"><i class="fa fa-plus"></i></button></div>
            </div>
            <br>
            <div class="form-group row" *ngIf="onDe15">
                <label for="description" class="col-md-3">Fifteenth Description</label>
                <div class="col-md-5 desc">
                    <input type="text" class="form-control" id="description15" name="description15" aria-placeholder="Invoice Description" rows="1" [(ngModel)]="invoice.description15" 
                   >
                </div>
                    <label  for="amount" class="col-md-2 amt control-label">Fifteenth  Amount</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.amount15" [(ngModel)]="invoice.amount15" >
                    </div>
            </div>
        </div>
        <br>
            <div class="form-group row">
                <label  for="gl-name" class="col-md-3 control-label">Companys Name:</label>
                <div class="col-md-4 desc">
                <select name="invoice.company" [(ngModel)]="invoice.company" class="custom-select form-control" aria-placeholder="select one company">
                    <option >JNC International Ltd</option>
                    
                </select>
            </div>

            <label  for="amount" class="col-md-2 amt control-label">Invoice Number</label>
                    <div class="col-md-2" >
                     <input type="text" aria-placeholder="Transaction Amount" class="form-control" name="invoice.number" [(ngModel)]="invoice.number" >
                    </div>
            </div>
            <br>
            <div class="form-group row">
                <label  for="amount" class="col-md-2 control-label">Air Way bill Number:</label>
                <div class="col-md-1 col-3 awb" >
                    <input type="text" aria-placeholder="AWB Number" class="form-control" name="invoice.awb1" [(ngModel)]="invoice.awb1" >
                   </div>
                <div class="col-md-2 col-9 cat" >
                 <input type="text" aria-placeholder="AWB Number" class="form-control" name="invoice.awb2" [(ngModel)]="invoice.awb2" >
                </div>
            </div>
            <br>
            <div class="switch primary"><input type="checkbox" name="invoice.vater" [(ngModel)]="invoice.vater" checked id="switch-2"><label for="switch-2">Calculate VAT?</label></div>
            <br>
            <div class="row">
            <label  for="amount" class="col-md-2 control-label">Date:</label>
             <div class="col-sm-3 form-group date">    
                <input  
                type="text"    
                placeholder="Invoice Date" 
                [(ngModel)]="invoice.date" 
                name="invoice.date"
                bsDatepicker  
                [bsConfig]="{ isAnimated: true }" class="form-control" style="height: 40px;"/>  
            </div>
            <br><br>
            <label  for="gl-name" class="col-md-3 control-label wizz">Cargo Type:</label>
            <div class="col-md-3 mess">
            <select name="invoice.cargo" [(ngModel)]="invoice.cargo" class="custom-select form-control" aria-placeholder="select one company">
                <option >PAAR</option>
                <option >Normal Import</option>
                <option >Export</option>
                
            </select>
        </div>
        </div>
        <br><br>
        <div class="row">
        <label  for="gl-name" class="col-md-3 control-label">Payment Bank:</label>
        <div class="col-md-3">
        <select name="invoice.bank" [(ngModel)]="invoice.bank" class="custom-select form-control" aria-placeholder="select payment bank">
            <option >Access Bank</option>
            <option >Zenith Bank</option> 
        </select>
    </div>
</div>
        <br><br>
        <div class='box'>
            <h6 class="card-title">Select a file for upload - Main Image</h6>
            <input type="file" (change)="upload1($event, 'url1')" accept=".png,.jpg" />
            <br><br>
            <div class="progress">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress1 | async) + '%'" [attr.aria-valuenow]="(uploadProgress1 | async)" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <br>
            <div>
                File uploaded: <a href="{{url1}}" target="_blank">Download Picture</a>
            </div>
            <div class="btn-group" role="group" *ngIf="uploadState1 | async; let state">
                <button type="button" class="btn btn-primary" (click)="task1.pause()" [disabled]="state === 'paused'">Pause</button>
                <button type="button" class="btn btn-primary" (click)="task1.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="task1.resume()" [disabled]="state === 'running'">Resume</button>
            </div>
            <br>
          <label>Select Photo Url</label>
          <select name="photo1" [(ngModel)]="invoice.photo1" class="custom-select"  #photo1="ngModel" id="photo1">
            <option value="{{url1 | slice:8}}">
              {{url1 | slice:8}}
            </option>
          </select>
         <br><br>
            <img src="{{url1}}" alt="">
            <br>
            <input type="text" class="form-control" id="title1" name="title1" placeholder="Document Name" rows="1" [(ngModel)]="invoice.title1">
          </div>
        <br><br>
        <div class="box">
          <h6 class="card-title">Select a file for upload:</h6>
          <input type="file" (change)="upload2($event, 'url2')" accept=".png,.jpg" />
          <br><br>
          <div class="progress">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress2 | async) + '%'" [attr.aria-valuenow]="(uploadProgress2 | async)" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <br>
          <div>
              File uploaded: <a href="{{url2}}" target="_blank">Download Picture</a>
          </div> 
          <div class="btn-group" role="group" *ngIf="uploadState2 | async; let state">
              <button type="button" class="btn btn-primary" (click)="task2.pause()" [disabled]="state === 'paused'">Pause</button>
              <button type="button" class="btn btn-primary" (click)="task2.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="task2.resume()" [disabled]="state === 'running'">Resume</button>
          </div>
                    
          <br>
          <label>Select Photo Url</label>
          <select name="photo2" [(ngModel)]="invoice.photo2" class="custom-select"  #photo2="ngModel" id="photo2">
            <option value="{{url2 | slice:8}}">
              {{url2 | slice:8}}
            </option>
          </select>
         <br><br> 
  
      <div>
          <img src="{{url2}}" alt="">
        </div>
        <br>
        <input type="text" class="form-control" id="title2" name="title2" placeholder="Document Name" rows="1" [(ngModel)]="invoice.title2">
      </div>
      <br> <br>
      
      <div class="box">
        <h6 class="card-title">Select a file for upload:</h6>
        <input type="file" (change)="upload3($event, 'url3')" accept=".png,.jpg" />
        <br><br>
        <div class="progress">
          <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress3 | async) + '%'" [attr.aria-valuenow]="(uploadProgress3 | async)" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <br>
        <div>
            File uploaded: <a href="{{url3}}" target="_blank">Download Picture</a>
        </div>
        <div class="btn-group" role="group" *ngIf="uploadState3 | async; let state">
            <button type="button" class="btn btn-primary" (click)="task3.pause()" [disabled]="state === 'paused'">Pause</button>
            <button type="button" class="btn btn-primary" (click)="task3.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="task3.resume()" [disabled]="state === 'running'">Resume</button>
        </div>       
        <br>
        <label>Select Photo Url</label>
        <select name="photo3" [(ngModel)]="invoice.photo3" class="custom-select"  #photo3="ngModel" id="photo3">
          <option value="{{url3 | slice:8}}">
            {{url3 | slice:8}}
          </option>
        </select>
       <br><br> 
    <div>
        <img src="{{url3}}" alt="">
      </div>
      <br>
      <input type="text" class="form-control" id="title3" name="title3" placeholder="Document Name" rows="1" [(ngModel)]="invoice.title3">
    </div>
  <br><br>
  <div class="box">
    <h6 class="card-title">Select a file for upload:</h6>
    <input type="file" (change)="upload4($event, 'url4')" accept=".png,.jpg" />
    <br><br>
    <div class="progress">
      <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width]="(uploadProgress4 | async) + '%'" [attr.aria-valuenow]="(uploadProgress4 | async)" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <br>
    <div>
        File uploaded: <a href="{{url4}}" target="_blank">Download Picture</a>
    </div>
    <div class="btn-group" role="group" *ngIf="uploadState4 | async; let state">
        <button type="button" class="btn btn-primary" (click)="task4.pause()" [disabled]="state === 'paused'">Pause</button>
        <button type="button" class="btn btn-primary" (click)="task4.cancel()" [disabled]="!(state === 'paused' || state === 'running')">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="task4.resume()" [disabled]="state === 'running'">Resume</button>
    </div>       
    <br>
    <label>Select Photo Url</label>
    <select name="photo4" [(ngModel)]="invoice.photo4" class="custom-select"  #photo4="ngModel" id="photo4">
      <option value="{{url4 | slice:8}}">
        {{url4 | slice:8}}
      </option>
    </select>
   <br><br> 
  <div>
  
    <img src="{{url4}}" alt="">
  </div>
  <br>
  <input type="text" class="form-control" id="title4" name="title4" placeholder="Document Name" rows="1" [(ngModel)]="invoice.title4">
  </div>
            <br><br>
            <div class="form-group margin-bottom-0 text-center">
                <div class="col-sm-offset-2 col-sm-10">
                    <button type="button" style="background-color: #537fb8; color: white;" class="btn btn-sm waves-effect waves-light" (click)="postForm.form.valid && onSubmit()" [disabled]="btnDisabled">Submit</button>
                    <img *ngIf="btnDisabled" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
            </div>
            
        </form>
    </div>
    <!-- /.card-content -->
        </div>
    </div>
</div>

<app-invoice-list></app-invoice-list>

