import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { RestApiService } from 'src/app/rest-api.service';
import { environment } from 'src/environments/environment';                      
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var $

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})

export class InvoiceComponent implements OnInit {                                                                                                                                                                                                                        
  config = environment;                                              
    @ViewChild('htmlData') htmlData:ElementRef;
    invoice: any ={ };
    invoiceId: any;
    btnDisabled= false;
    id: any;
    html2canvas;
    email: [];
    subject: '';
    message: '';
   link:"";
  
  
    constructor(
      public data: DataService,
      private rest: RestApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,  
    ) { }
  
   ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      this.invoiceId = res['id'];
      this.getinvoices();
  
      this.data.getProfile()
    });
    }
  
    // Get the products from the database
    async getinvoices() {
     
      try {
        const data = await this.rest.get(
          `https://teximarcobackend.herokuapp.com/api/operations/invoice/${this.invoiceId}`);
        data['success']
              ? (this.invoice = data['invoice'])
              : this.data.error(data['message']);       
      } catch (error) {
        this.data.error(error['message']);
      }
    }
  
  
    // Function to approve the Journal
    async paidInvoice(body: any, id: number){
      this.invoice.status = "paid";
      this.invoice.approvedDate = new Date();
      this.invoice.approvedBy = this.data.user.firstname + " " + this.data.user.surname;
          try{
            const data = await this.rest.updateInvoice( id, this.invoice);
            data['success']
              ? this.router.navigate([`invoice/${this.invoiceId}`])
                .then(() => this.data.success(data['message']))
                .catch(error => this.data.error(error))
              : this.data.error(data['message']);
              
          }
         catch (error) {
          this.data.error(error['message']);
        }
        this.btnDisabled = false;
    }
  
    async onSubmit(){
      this.btnDisabled = true; 
      
      try { 
          const data = await this.rest.post(
            `${this.config.emailUrl}`,        
            {
              email: this.email,
              subject: "Teximarco Invoice Number" + " " + this.invoice.number + " " + "for AWB No" + " " + this.invoice.awb1 + "" + "-"+ "" + this.invoice.awb2,
              message: this.message,
              invoiceNumber: this.invoice.number,
              invoiceDesc: this.invoice.description1,
              invoiceawb: this.invoice.awb2,
              invoiceawb1: this.invoice.awb1,
              invoicecomp: this.invoice.company,
              invoiceamt: this.invoice.total,
              invoicelink: window.location.href
            },
          );
          data['success']
            ? this.router.navigate(['/operations/journals'])
              .then(() => this.data.success(data['message']))
              .catch(error => this.data.error(error))
            : this.data.error(data['message']);
            window.alert('Successfully Sent');
            setTimeout(this.windowsReload.bind(this), 2000);
        
      } catch (error) {
        this.data.error(error['Successfully Sent']);
      }
      this.btnDisabled = false;
    }

    windowsReload(){
      window.location.reload();
    }


  // For printing the invoice
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print Journal</title>
         
     <link rel="stylesheet"  href="/assets/styles/invoice.css">
     <link rel="stylesheet" href="assets/styles/style.min.css" rel="stylesheet">
     <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1" crossorigin="anonymous">
     <link href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,400i,700,700i" rel="stylesheet">
     <link href="https://fonts.googleapis.com/css?family=Mukta:200,300,400,500,600,700,800" rel="stylesheet">
     <link href ="https://fonts.googleapis.com/css?family=Lato:400,400i,700" rel="stylesheet">
     <link rel="stylesheet" href="/styles.css">
     </head>
    <body onload="window.print(); window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  
  
  public downloadPDF(){
    let pdfContent = window.document.getElementById("print-section");
    pdfContent.style.marginRight = "870px"
    html2canvas((pdfContent)).then(function (canvas)
    {
      
      var imgWidth = 205;
      var pageHeight = 295;
      var leftHeight = pageHeight
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var img = canvas.toDataURL("image/png");
      var position = 0;
      var doc = new jsPDF('p', 'mm', 'a4');
      doc.addImage(img, 'PNG', 0, position, imgWidth, imgHeight)
      doc.save('teximarco-invoice.pdf' )
    })
    pdfContent.style.marginRight = "0px"
  }
  

}
