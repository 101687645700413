import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
