import { Component, OnInit } from '@angular/core';
declare var $: any

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    $('.simpleslide100').each(function(){
      var delay = 7000;
      var speed = 1000;
      var itemSlide = $(this).find('.simpleslide100-item');
      var nowSlide = 0;

      $(itemSlide).hide();
      $(itemSlide[nowSlide]).show();
      nowSlide++;
      if(nowSlide >= itemSlide.length) {nowSlide = 0;}

      setInterval(function(){
          $(itemSlide).fadeOut(speed);
          $(itemSlide[nowSlide]).fadeIn(speed);
          nowSlide++;
          if(nowSlide >= itemSlide.length) {nowSlide = 0;}
      },delay);
  });

  }

}
