export const invoice = {
        amount1: 0,
        description1: '',
        amount2: 0,
        description2: '',
        amount3: 0,
        description3: '',
        amount4: 0,
        description4: '',
        amount5: 0,
        description5: '',
        amount6: 0,
        description6: '',
        amount7: 0,
        description7: '',
        amount8: 0,
        description8: '',
        amount9: 0,
        description9: '',
        amount10: 0,
        description10: '',
        amount11: 0,
        description11: '',
        amount12: 0,
        description12: '',
        amount13: 0,
        description13: '',
        amount14: 0,
        description14: '',
        amount15: 0,
        description15: '',
        status: 'pending',
        preparedBy: '',
        company: '',
        date: '',
        awb1: '',
        awb2: '',
        cargo: '',
        number: '',
        subtotal:0, 
        total: 0,
        vat: 0,
        address:'',
        address2: '',
        vater: true,
        photo1: '',
        photo2: '',
        photo3: '',
        photo4: '',
        title1: '',
        title2: '',
        title3: '',
        title4: '',
        bank: '',
        accno: '',
        accname: '',
      };
