import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { RestApiService } from 'src/app/rest-api.service';
import { environment } from 'src/environments/environment';
import { ExportService } from '../export.service';
declare var $: any;

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {   
  @ViewChild('userTable') userTable: ElementRef;                                                                                                                                                                                                                        
  config = environment;                                              
    invoices: any;
    filterTerm: any;
    onGL;
    onDL;
   startdate ;
  enddate;
  dater;
  //days = [3,6,9,12];
  days = ["All", "Today", "Last 7 days", "Last 30 days"];
    page = 1;
    count = 0;
    tableSize = 10;
    tableSizes = [3, 6, 9, 12,20,25,50,100];
    datest: any;
    changeInvoice: any;
    unchangedInvoice: any;
  alltotal: any;
  removedtax: any;
    
  
    constructor( private rest: RestApiService,
                  private data: DataService,
                  private exportService: ExportService) { }
  
      // Get the invoice from the API using a GET request
    async ngOnInit() {
     this.Invoice();  
    }
    async Invoice(){
      try {
        const data = await this.rest.get(
          `${this.config.invoiceUrl}`        
        );
        data['success']
          ? (this.invoices = data['invoice'], this.unchangedInvoice = data['invoice'])
          : this.data.error(data['message']);
      } catch (error) {
        this.data.error(error['message']);
      }
      this.alltotal = this.Total
      this.removedtax = this.Total - this.taxremovedTotal
    }
  
    GLDL(){
      if (this.onGL){
       this.onGL = !this.onGL
      }else if (this.onDL) {
        this.onDL = !this.onDL
      }
    }
  
    
    onTableDataChange(event){
      this.page = event;
      this.dateChange(event);
    }  
  
    onTableSizeChange(event): void {
      this.tableSize = event.target.value;
      this.page = 1;
      this.dateChange(event);
    }  
  
  
    dateChange(event){ 
     if (this.datest == "All"){
        this.invoices = this.unchangedInvoice;
      } else {
        
       this.invoices = this.forChange(this.datest)
        
      }
    }
  
     forChange(selectionParam){
       var result;
       this.unchangedInvoice.forEach((element) => {
         if ( selectionParam == 'Today') {
           result = this.TodaysTrx
          } else if 
          (selectionParam ==  "Last 7 days"){
            result = this.last7daysTrx;
          } else if
           (selectionParam == "Last 30 days"){
            result = this.last30daysTrx;
          } else {
            result = this.unchangedInvoice;
          }
       });
       return result
     }
     
      // Function to get a particular date format
     formatDate(date){
   
        var dd = date.getDate();
        var mm = date.getMonth()+1;
        var yyyy = date.getFullYear();
        if(dd<10) {dd='0'+dd}
        if(mm<10) {mm='0'+mm}
        date = mm+'/'+dd+'/'+yyyy;
        return date
     }
  
     // Function to get the transactions made today
       get TodaysTrx(){
       var total = [];
       this.unchangedInvoice.forEach((element) =>{
         this.todaysDate.forEach((data) => {
          if (element.date == data){
            total.push(element)
        }
         })
        
       })
        return total
     }
  
  
     // Function to get the transactions made in the last 7 days
     get last7daysTrx(){
      var total = [];
     this.unchangedInvoice.forEach((element) =>{
       this.Last7days.forEach((data) => {
        if (element.date == data){
          total.push(element)
      }
       })
      
     })
      return total
    }

    get pendingTrx(){
      var total = [];
      this.unchangedInvoice.forEach((element) =>{
         if (element.status === "pending"){
           total.push(element)
       }
        })
      return total
    }

    pending(){
      this.invoices = this.pendingTrx
    }
    get paidTrx(){
      var total = [];
      this.unchangedInvoice.forEach((element) =>{
         if (element.status === "paid"){
           total.push(element)
       }
        })
      return total
    }

    paid(){
      this.invoices = this.paidTrx
    }

     // Function to get the transactions made in the last 30 days
     get last30daysTrx(){
      var total = [];
     this.unchangedInvoice.forEach((element) =>{
       this.last30Days.forEach((data) => {
        if (element.date == data){
          total.push(element)
      }
       })
      
     })
      return total
    }
  
     // Function to get the last 7 days in a particular format
    get Last7days(){
      var result = [];
      for (var i=0; i<7; i++) {
          var d = new Date();
          d.setDate(d.getDate() - i);
          result.push( this.formatDate(d) )
      }
      return( result);
    }
  
    // Function to get todays Date in a particular time format
    get todaysDate(){
      var result = [];
     var d = new Date();
     d.setDate(d.getDate())
     result.push(this.formatDate(d))
     return (result);
    }
  
  
      // Function to get the last 30 days in a particular format
      get last30Days(){
        var result = [];
        for (var i=0; i<30; i++) {
            var d = new Date();
            d.setDate(d.getDate() - i);
            result.push( this.formatDate(d) )
        }
        return(result);
      }
  
  // Function to get all dates between the start date and end date in the date search box  
   get dan(){
    let dates = []
   var  result = []
    //to avoid modifying the original date
    const theDate = new Date(this.startdate)
    while (theDate < this.enddate) {
      dates = [...dates, new Date(theDate)]
      theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, this.enddate]
  
    dates.forEach((element) =>{
      result.push(this.formatDate(element)) 
    })
    return result
   }
  
   
   // Function to get the transactions made during the search dates
   get dane(){
    var total = [];
    this.unchangedInvoice.forEach((element) =>{
      this.dan.forEach((data) => {
       if (element.date == data){
         total.push(element)
     }
      })
     
    })
     return total
   }

   get searchTotal(){
     var total = 0
     this.dane.forEach((data) => {
      total += data.total 
     })
     return total
   }
  
  // listen event to search for the transaction during the searched dates 
  searchdate(){
      this.invoices = this.dane
      this.alltotal = this.searchTotal 
  }
  
  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'teximarco_invoiceList');
  }
  
  get Total(){
    var total = 0
    this.invoices.forEach((data) => {
     total += data.total 
    })
    return total
  }

  get taxremovedTotal(){
    try {
      var total = 0;
    this.invoices.forEach((data) => {
      var no = data.number
      var indexedNumber = ''
     indexedNumber = no.charAt(3)
     if (indexedNumber === "A"){
      total += (data.subtotal*(5/100))
     }
    })
    return total 
    } catch (error) {  
    }
  }

  get totalPending(){
    try {
      var total = 0;
    this.invoices.forEach((data) => {
     if (data.status === "pending"){
      total += data.total
     }
    })
    return total 
    } catch (error) {  
    }
  }

  get totalpaid(){
    try {
      var total = 0;
    this.invoices.forEach((data) => {
     if (data.status === "paid"){
      total += data.total
     }
    })
    return total 
    } catch (error) {  
    }
  }
}
