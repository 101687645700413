import { Injectable, ElementRef } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor() { }
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }
  // /**
  //  * Creates excel from the table element reference.
  //  *
  //  * @param element DOM table element reference.
  //  * @param fileName filename to save as.
  //  */
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);

  }


  // @param rows array of data to be converted to CSV.
  // * @param fileName filename to save as.
  // * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
  // */
 public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
   try {
   if (!rows || !rows.length) {
     return;
   }
   const separator = ',';
   const keys = Object.keys(rows[0]).filter(k => {
     if (columns?.length) {
       return columns.includes(k);
     } else {
       return true;
     }
   });
   const csvContent =
     keys.join(separator) +
     '\n' +
     rows.map(row => {
       return keys.map(k => {
         let cell = row[k] === null || row[k] === undefined ? '' : row[k];
         cell = cell instanceof Date
           ? cell.toLocaleString()
           : cell.toString().replace(/"/g, '""');
         if (cell.search(/("|,|\n)/g) >= 0) {
           cell = `"${cell}"`;
         }
         return cell;
       }).join(separator);
     }).join('\n');
   this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);  
  } catch (error) {
     
  }
 }
}

