import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataService } from './data.service';
import { LoginComponent } from './login/login.component';
import { RestApiService } from './rest-api.service';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';  
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { InvoiceComponent } from './invoice/invoice.component';
import { SendMailComponent } from './send-mail/send-mail.component';
import { AngularFireModule } from "@angular/fire";
//import { AngularFireAuthModule, AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule} from '@angular/fire/storage'
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    HomeComponent,
    DashboardComponent,
    CreateInvoiceComponent,
    InvoiceListComponent,
    InvoiceComponent,
    SendMailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule,
    NgxPaginationModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    //AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
  ],
  providers: [RestApiService, DataService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
