import { Component, OnInit } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {                                                                                                                                                                                        
                                    
                                    
config = environment;                                              
  btnDisabled = false;
  firstname = "";
  surname = "";
  email = "";
  password = "";
 

  constructor(
    private router: Router,
    public data: DataService,
    private rest: RestApiService,
  ) { }


  ngOnInit() {
    this.data.getProfile();
  }

 
  validate() {
    if (this.firstname) {
      if(this.surname) {
      if (this.email) {
        if (this.password) {
              return true;
        } else {
          this.data.error('Password is not entered');
        }
      } else {
        this.data.error('Email is not entered.');
      }
    } else {
      this.data.error('Surname is not entered.');
    }
    } else {
      this.data.error('First name is not entered.');
    }
  }


  async register() {
    this.btnDisabled = true;
    try {
      if (this.validate()) {
        const data = await this.rest.post(
          `${this.config.signUpUrl}`,        
          {
            firstname: this.firstname,
            surname: this.surname,
            email: this.email,
            password: this.password,
          },
        );
        if (data['success']) {
          localStorage.setItem('token', data['token']);
          await this.data.getProfile();
          this.data.success('Registration successful!');
          //setTimeout(this.windowsReload.bind(this), 3000);
          this.router.navigate(['/dashboard']);
        } else {
          this.data.error(data['message']);
        }
      }
    } catch (error) {
      this.data.error(error['message']);
    }
    this.btnDisabled = false;
  }

  windowsReload(){
    window.location.reload();
  }  
}

