<div class="container-fluid" style="background-color: grey; height: 70px;">
    <h2 class="text-center text-white head" style="text-align: center; font-weight: bolder; font-size: larger; padding-top: 20px;">INVOICE No. {{invoice.number}} for {{invoice.company}}</h2>
</div>
<button *ngIf="this.data.user?.firstname === 'David'" type="button" class="btn" style="margin-left: 25px; margin-top: 45px; background-color: #537fb8;"> <a   style="color: white;" routerLink="/dashboard"><<< Back to Dashboard</a></button>
<div class="text-right pull-right margin-top-20">
   
        <button type="button" style="margin-right: 10px;" class="btn btn-success waves-effect waves-light" (click)="print()"><i class='fa fa-print'></i> Print</button>
        <button type="button"  style="margin-right: 10px;" class="btn btn-secondary btn-round waves-effect waves-light" (click)="downloadPDF()"><i class='fa fa-download'></i> Download Pdf</button><h4 style="color: red;">Please download using laptop/desktop</h4>
    
</div>
<div style="margin-left: 40px;" >
    <h2 style="font-weight: bolder; font-size: x-large;">Please Pay to {{invoice.bank}} </h2>
    <h2  style="font-weight: bolder; font-size: x-large;">Account Number: {{invoice.accno}}</h2>
    <h2  style="font-weight: bolder; font-size: x-large;">Account Name: {{invoice.accname}}</h2></div>
<br>
<div *ngIf= "invoice.status === 'pending'" class="text-center"><h1 style="font-size: x-large; font-weight: bolder;" class=" text-dark" >STATUS:</h1>  <h1 style="font-size: x-large; font-weight: bolder;" class=" text-warning">PENDING</h1></div>
<div *ngIf= "invoice.status === 'paid'" class="text-center"> <h1 style="font-size: x-large; font-weight: bolder;" class=" text-dark" >STATUS:</h1><h1 style="font-size: x-large; font-weight: bolder;" class="text-success"> PAID</h1></div>
<br><br>
<div id="print-section">
    <div>
<div id="page-wrap" style="border: 2px solid black; padding: 5px;">
    <div id="header">TEXIMARCO INVOICE</div>
    <div id="identity" class="row" >
        <div class="col-6 col-md-6">
     <img class="imang" id="image" src="/assets/images/teximarco-logo.png" alt="logo" /> 
    
<div class="address">
<div class="adds"> TEXIMARCO INTERNATIONAL LTD</div><br>  
<div class="add"> 84, NEW EBUTE SHOPPING COMPLEX,</div><br> 
<div class="add">IKORODU, LAGOS</div>
</div> 
</div>
 
 <div id="customer" class="col-6 col-md-6 details">
 <table id="meta" style="border: 2px solid black;">
  <tr>
      <td class="meta-head">INVOICE NO.</td>
      <td  class="text-center">{{invoice.number}}</td>
  </tr>
  <tr>

      <td class="meta-head">DATE</td>
      <td class="text-center"><div id="date" >{{invoice.date | date:'mediumDate'}}</div></td>
  </tr>
  <tr>
      <td class="meta-head">PAGE</td>
      <td class="text-center">1</td>
  </tr>
  <tr>
      <td class="meta-head" >AWB NUMBER</td>
      <td><div class="due text-center">{{invoice.awb1}}-{{invoice.awb2}}</div></td>
  </tr>
 </table>
    
    </div>
</div>
<br><br>

<div class="row small-spacing">
        <div  class="col-md-6 col-6 jnci-box text-left" style="border: 2px solid black; text-align: left !important;">
                <div class="content widget-stat">
                    <div class=" jnci">
                        <h2>
                            <div>JNC International Ltd,</div> 
                            <div>30, Raymond Njoku Street,</div>
                            <div>Ikoyi, Lagos</div>
                         </h2>
                    </div>
                </div>
        </div> 
        
        <div  class="col-md-6 col-6 deliver-to-box text-left" style="border: 2px solid black; text-align: left !important;">
            <div class="content widget-stat">
                <div class=" deliver-to">
                    <h2>
                        <div class="deliver">Deliver to</div> 
                        <div class="deliver">825-1017-7543</div>
                        <div class="deliver">Document delivery</div>
                        <div class="deliver">7543/1-057-98618861</div>
                        <div class="deliver">Collect Charges</div>
                     </h2>
                </div>
            </div>
    </div>
</div>
<br>
<div class="row reference1">
    <table class="col-12 col-md-12 text-center reference" style="border: 2px solid black;">
        <tr>
            <th>Account</th>
            <th>Your Reference</th>
            <th>Tax Exempt</th>
            <th>Tax Reference</th>
            <th>Sale Code</th>
            <th>Cost Code</th>
            <th></th>
        </tr>
        <tr>
            <td>JNC</td>
            <td>024</td>
            <td>NIL</td>
            <td></td>
            <td></td>
            <td>48914</td>
            <td>Exclusive</td>
        </tr>
    </table>
</div>

<div class="item">
 <table id="items" class="reference">
    
      <tr>
        <th>Code</th>
          <th>Description</th>
          <th>Unit Price</th>
      </tr>
      
      <tr class="cs">
          <td class="item-name"><div *ngIf = "invoice.description1 != ''" > C.S</div></td>
          <td class="description desc"><div *ngIf = "invoice.description1 != ''">{{invoice.description1}}</div></td>
          <td class="item-price" ><span class="price"  *ngIf = "invoice.amount1 != 0">₦{{invoice.amount1 | number}}</span></td>
      </tr>
      <tr class="cd styler">
        <td class="item-name"><div *ngIf = "invoice.description2 != ''" > C.S</div></td>
        <td class="description desc"><div *ngIf = "invoice.description2 != ''">{{invoice.description2}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount2 != 0">₦{{invoice.amount2 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description3 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description3 != ''">{{invoice.description3}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount3 != 0">₦{{invoice.amount3 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description4 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description4 != ''">{{invoice.description4}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount4 != 0">₦{{invoice.amount4 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description5 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description5 != ''">{{invoice.description5}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount5 != 0">₦{{invoice.amount5 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description6 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description6 != ''">{{invoice.description6}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount6 != 0">₦{{invoice.amount6 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description7 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description7 != ''">{{invoice.description7}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount7 != 0">₦{{invoice.amount7 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description8 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description8 != ''">{{invoice.description8}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount8 != 0">₦{{invoice.amount8 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description9 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description9 != ''">{{invoice.description9}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount9 != 0">₦{{invoice.amount9 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description10 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description10 != ''">{{invoice.description10}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount10 != 0">₦{{invoice.amount10 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description11 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description11 != ''">{{invoice.description11}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount11 != 0">₦{{invoice.amount11 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description12 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description12 != ''">{{invoice.description12}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount12 != 0">₦{{invoice.amount12 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description13 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description13 != ''">{{invoice.description13}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount13 != 0">₦{{invoice.amount13 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description14 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description14 != ''">{{invoice.description14}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount14 != 0">₦{{invoice.amount14 | number}}</span></td>
    </tr>
    <tr class="cd">
        <td class="item-name"><div *ngIf = "invoice.description15 != ''" > C.S</div></td>
        <td class="description"><div *ngIf = "invoice.description15 != ''">{{invoice.description15}}</div></td>
        <td class="item-price"><span class="price"  *ngIf = "invoice.amount15 != 0">₦{{invoice.amount15 | number}}</span></td>
    </tr>
    </table>
</div>
    
    <div id="terms">   
      <h5 class="awbn">AWB NUMBER: {{invoice.awb1}}-{{invoice.awb2}}({{invoice.cargo}})</h5>
      <div class="signed">Approved and Signed By: {{invoice.preparedBy}}</div>
    </div>
    <br>
    <div class="row small-spacing">
        <div  class="col-md-6 col-6 jnci-box" style="border: 2px solid black;">
                <div class="content widget-stat">
                    <div class="text-center deliver-to">
                        <h2>
                            <div class="deliver">LOCAL EXPRESS DELIVERY/ INTERNATIONAL DELIVERY</div>
                            <div class="row">
                            <div class="deliver col-md-6 col-6">EURO PAYMENTS</div>
                            <div class="deliver col-md-6 col-6">USD PAYMENTS</div>
                        </div>
                        <div>Please Pay to {{invoice.bank}}
                            <br> Account Number: {{invoice.accno}}
                        <br> Account Name: {{invoice.accname}}</div>
                         </h2>
                    </div>
                </div>
        </div> 
        
        <div  class="col-md-6 col-6 delivers-to-box">
                    <table id="meta" style="border: 2px solid black;">
                        <tr>
                            <td class="meta-head">Sub Total</td>
                            <td  class="text-center">₦{{invoice.subtotal | number}}</td>
                        </tr>
                        <tr>
                      
                            <td class="meta-head">Amount Excl Tax</td>
                            <td class="text-center"><div id="date" >₦{{invoice.subtotal | number}}</div></td>
                        </tr>
                        <tr>
                            <td class="meta-head">VAT 5%</td>
                            <td class="text-center">₦{{invoice.vat | number}}</td>
                        </tr>
                        <tr>
                            <td class="meta-head" >Total</td>
                            <td><div class="due text-center">₦{{invoice.total | number}}</div></td>
                        </tr>
                       </table>
    </div>
</div>

</div>
</div>
</div>
<br><br>
<h2 *ngIf = "invoice.photo1" class="text-center" style="font-weight: bolder; font-size: 50px; font: outline; border-bottom: 2px solid black;">Other Documents</h2>

<br>
<div style="text-align: center;">
    <div *ngIf = "invoice.photo1" class=" card-blog-img imagebox" >
     <a href="https://{{invoice.photo1}}"> <img src="/assets/images/adobe-pdf-file-icon-logo-vector.png" alt="" class="img" style="height: 100px;"></a>
      <span   class="imagebox-desc"> {{invoice.title1}}</span>
      <br>
     <button style=" background-color: rgb(140, 140, 243); height: 50px; width: 160px;"> <a href="https://{{invoice.photo1}}" download style="color: white; "> Download pdf</a></button>
    </div>
    <br>

    <div *ngIf = "invoice.photo2" class=" card-blog-img imagebox" >
           <a href="https://{{invoice.photo2}}"> <img src="/assets/images/adobe-pdf-file-icon-logo-vector.png" alt="" class="img" style="height: 100px;"></a>
        <span   class="imagebox-desc"> {{invoice.title2}}</span>
        <br>
        <button style=" background-color: rgb(140, 140, 243); height: 50px; width: 160px;"> <a href="https://{{invoice.photo2}}" download style="color: white; "> Download pdf</a></button>
      </div>
      <br>
      
      <div *ngIf = "invoice.photo3" class=" card-blog-img imagebox" >
        <a href="https://{{invoice.photo3}}"> <img src="/assets/images/adobe-pdf-file-icon-logo-vector.png" alt="" class="img" style="height: 100px;"></a>
        <span   class="imagebox-desc"> {{invoice.title3}}</span>
        <br>
        <button style=" background-color: rgb(140, 140, 243); height: 50px; width: 160px;"> <a href="https://{{invoice.photo3}}" download style="color: white; "> Download pdf</a></button>
      </div>
      <br>
      <div *ngIf = "invoice.photo4" class=" card-blog-img imagebox" >
        <a href="https://{{invoice.photo4}}"> <img src="/assets/images/adobe-pdf-file-icon-logo-vector.png" alt="" class="img" style="height: 100px;"></a>
        <span   class="imagebox-desc"> {{invoice.title4}}</span>
        <br>
        <button style=" background-color: rgb(140, 140, 243); height: 50px; width: 160px;"> <a href="https://{{invoice.photo4}}" download style="color: white; "> Download pdf</a></button>
      </div>
  </div>
<br><br><br>

<div *ngIf="this.data.user?.firstname === 'David'">
    <div class="main-content container">
<div class="box-content card white">
    <h4 class="box-title">Send Email</h4>
    <!-- /.box-title -->
    <div class="card-content ">
<div class="col-md-12 col-12">
    <div class="box-content">
<div class="col-lg-6 great mt-5 mt-lg-0 text-center">
    
<form method="post " return="true"  id="form" role="form"  class="php-email-form">
<div class="form-row">
  <div class="col-md-6 form-group">
    <input type='email' placeholder='Email' data-rule="email" data-msg="Please enter a valid email" style="width: 700px !important;" name="email" [(ngModel)]="email"  class='flexdatalist form-control' data-min-length='1' multiple='multiple' list='languages'>
    <datalist id="languages">
        <option value="oadeboye@jnciltd.com">oadeboye@jnciltd.com</option>
        <option value="cogili@jnciltd.com">cogili@jnciltd.com</option>
        <option value="bopara@jnciltd.com">bopara@jnciltd.com</option>
        <option value="teximarco@yahoo.com">teximarco@yahoo.com</option>
        <option value="aakinyoola@jnciltd.com">aakinyoola@jnciltd.com</option>
        <option value="tshittu@jnciltd.com">tshittu@jnciltd.com</option>
    </datalist>
    <div class="validate"></div>
  </div>					
</div>
<br>
<!-- <div class="form-group">
  <textarea class="form-control" style="border: 1px solid black; background-color: white;" name="message" rows="5" [(ngModel)]="message" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
  <div class="validate"></div>
</div> -->
<br><br>
<div class="text-center"><button class="btn btn-success" type="button" (click)="onSubmit()" reset='true'>Send Message</button></div>
</form>
</div>
    </div>
</div>
    </div>
   <div class="text-center"> <button class="btn btn-success" type="button" (click)= "paidInvoice(invoice.body, invoice._id)"> Paid</button></div>
</div>
    </div>
</div>
<br><br><br>

<!--
<footer >
    <svg viewBox="0 0 120 28">
     <defs> 
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="
               1 0 0 0 0  
               0 1 0 0 0  
               0 0 1 0 0  
               0 0 0 13 -9" result="goo" />
         
        </filter>
         <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
      </defs> 
    
       <use id="wave3" class="wave" xlink:href="#wave" x="0" y="-2" ></use> 
       <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0" ></use>
     
     
      <g class="gooeff" filter="url(#goo)">
      <circle class="drop drop1" cx="20" cy="2" r="8.8"  />
      <circle class="drop drop2" cx="25" cy="2.5" r="7.5"  />
      <circle class="drop drop3" cx="16" cy="2.8" r="9.2"  />
      <circle class="drop drop4" cx="18" cy="2" r="8.8"  />
      <circle class="drop drop5" cx="22" cy="2.5" r="7.5"  />
      <circle class="drop drop6" cx="26" cy="2.8" r="9.2"  />
      <circle class="drop drop1" cx="5" cy="4.4" r="8.8"  />
      <circle class="drop drop2" cx="5" cy="4.1" r="7.5"  />
      <circle class="drop drop3" cx="8" cy="3.8" r="9.2"  />
      <circle class="drop drop4" cx="3" cy="4.4" r="8.8"  />
      <circle class="drop drop5" cx="7" cy="4.1" r="7.5"  />
      <circle class="drop drop6" cx="10" cy="4.3" r="9.2"  />
      
      <circle class="drop drop1" cx="1.2" cy="5.4" r="8.8"  />
      <circle class="drop drop2" cx="5.2" cy="5.1" r="7.5"  />
      <circle class="drop drop3" cx="10.2" cy="5.3" r="9.2"  />
        <circle class="drop drop4" cx="3.2" cy="5.4" r="8.8"  />
      <circle class="drop drop5" cx="14.2" cy="5.1" r="7.5"  />
      <circle class="drop drop6" cx="17.2" cy="4.8" r="9.2"  />
      <use id="wave1" class="wave" xlink:href="#wave" x="0" y="1" />
     </g>  
     
        <path   id="wave1"  class="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
        </g>
     
    
    </svg>

    </footer>
    -->