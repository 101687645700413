import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceComponent } from './invoice/invoice.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuard], },
  { path: 'admin', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'create-invoice', component: CreateInvoiceComponent, canActivate: [AuthGuard], },
  { path: 'invoice-list', component: InvoiceListComponent, canActivate: [AuthGuard], },
  {path:'invoice/:company/:number/:awb1-:awb2/:id', component: InvoiceComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
