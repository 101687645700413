<section id="mails">
    <br><br>
    <div class="container p-5">
        <button type="button"  class="btn" style="font-size: larger; background-color: #537fb8; color: white;" class="pull-right"><a (click)="logout()">Log Out</a></button>
      <h4 class="display-4">DashBoard</h4>
    
      <hr>
      
      <div  class="list-group">
        <a>
        <h2><a routerLink = "/create-invoice">Create Invoice</a></h2>
        <h2><a routerLink = "/invoice-list">View Invoices</a></h2>
        
        </a>
      </div>
      </div>
</section>