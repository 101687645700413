<section class="main">
	<div class="layer">
	
		<div class="content-w3ls">
			<div class="text-center icon">
				<span><img class="images" src="/assets/images/teximarco-logo.png" alt="Logo"></span>
			</div>
			<div class="content-bottom">
				<form  [formGroup]="loginForm">
					<div class="field-group form-group">
						<span class="fa fa-user" aria-hidden="true"></span>
						<div class="wthree-field">
							<input name="email" class="form-control" id="text1" type="email" value="" placeholder="Email" required [(ngModel)]="email" 
							[ngClass]="{'is-invalid': btnDisabled &&  loginForm.controls['email'].invalid}"
							formControlName="email" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
							<div *ngIf=" btnDisabled && loginForm.controls['email'].invalid" style="color: red;" >Invalid Email
								Address</div>
						</div>
					</div>
					<div class="field-group form-group">
						<span class="fa fa-lock" aria-hidden="true"></span>
						<div class="wthree-field ">
							<input name="password" class="form-control" id="myInput" type="Password" placeholder="Password"
							 [(ngModel)]="password" [ngClass]="{'is-invalid': btnDisabled && loginForm.controls['password'].invalid}"
							 formControlName="password" autocomplete="off" readonly onfocus="this.removeAttribute('readonly');">
							<div *ngIf=" btnDisabled && loginForm.controls['password'].invalid" style="color: red;">Invalid
								Passowrd</div>
						</div>
					</div>
					<div class="wthree-field form-group">
						<button type="button" class="btn"  (click)="login()" [disabled]="btnDisabled">Login</button>
						<img *ngIf="btnDisabled" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
				
				</form>
			</div>
		</div>
    </div>
</section>