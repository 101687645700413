<div>
    <div class="main-content container">
<div class="box-content card white">
    <h4 class="box-title">Teximarco Intl Ltd invoice List</h4>
    <!-- /.box-title -->
    <div class="card-content ">
<div class="col-md-12 col-12">
    <div class="box-content">
      
        <div class="box-title"><span style="margin-left: 70px;"><button class="btn btn-success text-center" (click)="exportElmToExcel()">Export as xlsx</button> </span>
            <div class="row">
        <!-- <h2 style="font-size: larger;" class="heads">View Transaction by:  
             <div class="form-group">
            <div class="col-md-2" >
            <select   [(ngModel)]="datest" name="datest" style="height: 38px; width: 110px; margin-left: 180px; margin-top: -32px;"  
              class="custom-select form-control" (change)="dateChange($event)" >
             <option  *ngFor="let i of days" [ngValue]="i">{{i}}</option>
            </select>
        </div>
        </div>
       </h2> -->
    </div>
        <!-- <div class="form-group pull-right" >
            <input type="text" class="form-control" *ngIf ="onGL"  style="width: 250px; margin-top: -45px; height: 40px; " placeholder="Search GL Number/Description/Status" [(ngModel)]="filterTerm">
          </div> -->

            <form  novalidate  >  
              <div class="row pull-right" style="margin-top:-45px; margin-right: -200px;">    
               
            <div class="col-sm-3 form-group" style="margin-left: 15px; margin-right: -15px;">    
                <input  
                type="text"    
                placeholder="From Date" 
                [(ngModel)]="startdate" 
                name="startdate"
                bsDatepicker  
                [bsConfig]="{ isAnimated: true }" class="form-control" style="height: 40px;"/>  
            </div>     
            <div class="col-sm-3 form-group" style="margin-left: -2px; margin-right: -15px;">    
                <input type="text"    
                placeholder="To Date"
                [(ngModel)]="enddate" 
                name="enddate" 
                bsDatepicker  
                [bsConfig]="{ isAnimated: true }"  class="form-control " style="height: 40px;"/>  
            </div>    
            <div class="col-sm-3 form-group" style="margin-left: -2px; margin-right: -11px;">    
                <button type="button" class="btn btn-success" (click)="searchdate()" style="height: 40px;"  >Search</button>   
            </div>  
          </div>  
            </form>        
        <!-- <div class="btn-group pull-right headi" style="margin-top: -45px;"> 
            <button type="button" class="btn btn-success" (click)="GLDL()" >Search by</button>
            <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="sr-only">Toggle Dropdown</span> </button>
            <div class="dropdown-menu">
                <button  (click)="onGL = !onGL && !onDL = onDL">GL Number</button>
                <button  (click)="onDL = !onDL && !onGL = onGL">Date</button>
            </div>
        </div>  -->

        <button type="button" class="btn btn-success" (click)="pending()" >Pending Trx</button>
        <button type="button" class="btn btn-success" (click)="paid()" >Paid Trx</button>
       <div> Total: ₦ {{alltotal  | number}}----- Total After Tax: ₦ {{removedtax | number}}--- Total Pending: ₦ {{totalPending | number}} --- Total Paid: ₦ {{totalpaid | number}}</div>
        <div class="d-flex flex-row-reverse bd-highlight mb-2 size" style="margin-top: -45px;">
            <div class="p-2 bd-highlight">
              <select (change)="onTableSizeChange($event)" class="custom-select">
                <option *ngFor="let size of tableSizes" [ngValue]="size">
                  {{ size }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- /.box-title -->


        <table style="border: 1px solid;" class="table table-bordered table-striped table-responsive margin-bottom-10" #userTable>
            <thead style="border: 1px solid;">
                <tr>
                    <th style="border: 1px solid;">Invoice Number</th>
                    <th style="border: 1px solid;">AWB Number</th>
                    <th style="border: 1px solid;">Companys Name</th>
                    <th style="border: 1px solid;">Invoice Date</th>
                    <th style="border: 1px solid;">Cargo Type</th>
                    <th style="border: 1px solid;" class="text-center">First Description</th>
                    <th style="border: 1px solid;">Invoice Total</th>
                    <th style="border: 1px solid;"> Date created</th>
                    <th style="border: 1px solid;">Status</th>
                    <th style="border: 1px solid;" style="width:5%;">View</th>
                </tr>
            </thead>
            <tbody style="border: 1px solid;">
                <tr *ngFor="let invoice of invoices | filter:filterTerm | paginate : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index" [class.active]="i == currentIndex">
                    <td style="border: 1px solid;" scope="row">{{invoice.number}}</td>
                    <td style="border: 1px solid;">{{invoice.awb1}}-{{invoice.awb2}}</td>
                    <td style="border: 1px solid;">{{invoice.company}}</td>
                    <td style="border: 1px solid;">{{invoice.date | date:'mediumDate'}}</td>
                    <td style="border: 1px solid;">{{invoice.cargo}}</td>
                    <td style="border: 1px solid;">{{invoice.description1}}</td>
                    <td style="border: 1px solid;"> ₦{{invoice.total | number}}</td>
                    <td style="border: 1px solid">{{invoice.created | date:'mediumDate'}}</td>
                    <td style="border: 1px solid black;" class="text-warning" *ngIf = "invoice.status === 'pending'"> {{invoice.status}}</td>
                    <td style="border: 1px solid black;" class="text-success" *ngIf = "invoice.status === 'paid'"> {{invoice.status}}</td>
                    <td style="border: 1px solid;"><a routerLink="/invoice/{{invoice.company}}/{{invoice.number}}/{{invoice.awb1}}-{{invoice.awb2}}/{{invoice._id}}"><i class="fa fa-plus-circle"></i></a></td>
               </tr>
            </tbody>
        </table>

         <div class="d-flex justify-content-center">
    <pagination-controls 
      responsive="true" 
      previousLabel="Prev" 
      nextLabel="Next" 
      (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </div>
    </div>
    <!-- /.box-content -->
</div>
</div>
</div>
    </div>
</div>
<br>