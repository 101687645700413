import { Component, OnInit } from '@angular/core';
import {  FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataService } from '../data.service';
import { RestApiService } from '../rest-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {                                                                                                                                                                                        
                                    
                                    
config = environment;                                              
  email ='';
  password = '';
  loginForm: FormGroup;
  btnDisabled = false;
 

  constructor(
    private router: Router,
    private rest: RestApiService,
    private data: DataService,
    private formBuilder: FormBuilder
  ) { 
        /* Declare Reactive Form Group here */
        this.loginForm = this.formBuilder.group({
          email: ['', [
            Validators.required,
            Validators.pattern("[^ @]*@[^ @]*")
          ]],
          password: ['', [
            Validators.minLength(8),
            Validators.required
          ]],
        });
  }

  ngOnInit() {
    this.data.getProfile();
  }


  
  validate() {
    if (this.email) {
      if (this.password) {
        return true;
      } else {
        this.data.error('Password is not entered');
      }
    } else {
      this.data.error('Email is not entered.');
    }
  }

  async login() {

    this.email =this.loginForm.controls.email.value;
    this.password = this.loginForm.controls.password.value;
    this.btnDisabled = true;
    if (this.loginForm.invalid) {
      return;
    }

    try {
      if (this.validate()) {
        const data = await this.rest.post(
          `${this.config.loginUrl}`,        
          {
            email: this.email,
            password: this.password,
          },
        );
        if (data['success']) {
          localStorage.setItem('token', data['token']);
          await this.data.getProfile();
          this.router.navigate(['/dashboard']);
        } else {
          this.data.error(data['message']);
        }
      }
    } catch (error) {
      this.data.error(error['message']);
    }
    this.btnDisabled = false;
  }
}
