<div class="main-content">
<div class="box-content card white">
  <!-- /.box-title -->
  <div class="card-content">
<div >
<section id="settings">
    <div class="container p-5">
      <h4 class="display-4">Staffs Registration</h4>
      <br>

      <div class="card bg-light">
        <div class="card-body">
          <form>
            <div class="form-group">
              <label class="form-control-label">First Name</label>
              <input type="text" name="fstname" class="form-control" [(ngModel)]="firstname">
            </div>
            <br>
            <div class="form-group">
                <label class="form-control-label">Surname</label>
                <input type="text" name="surname" class="form-control" [(ngModel)]="surname">
              </div>
              <br>
            <div class="form-group">
              <label class="form-control-label"> Company's Email Address</label>
              <input type="email" name="email" class="form-control" [(ngModel)]="email" >
            </div>
            <br>
            <div class="form-group">
              <label class="form-control-label">Password</label>
              <input type="password" name="pwsd" class="form-control" [(ngModel)]="password">
            </div>
              <br>
              <div> 
            </div>
              <br>
            <button type="button" style="margin-bottom: 20px; margin-left: 30px; background-color: #537fb8;" class="btn"  (click)="register()" [disabled]="btnDisabled" >Register</button>
          </form>
        </div>
      </div>
  </div>
  </section>
  </div>
  </div>
</div>
  </div>
