
//including required modules and services 
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RestApiService } from './rest-api.service';


//Exporting the DataService
@Injectable()
export class DataService {
  message = '';
  messageType = 'danger';

  user: any;
  config = environment; 

  constructor(private router: Router, private rest: RestApiService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.message = '';
      }
    });
  }

  error(message) {
    this.messageType = 'danger';
    this.message = message;
  }

  success(message) {
    this.messageType = 'success';
    this.message = message;
  }

  warning(message) {
    this.messageType = 'warning';
    this.message = message;
  }

  async getProfile() {
    try {
      if (localStorage.getItem('token')) {
        const data = await this.rest.get(
          `${this.config.profileUrl}`,        
        );
        this.user = data['user'];
      }
    } catch (e) {
      this.error(e);
    }
    
  }

  async logoutUser() {
    // remove user from local storage to log user out
    this.user = {};
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(['/admin'])    
  }

}
